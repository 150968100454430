import useSWR from 'swr'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import fetcher from '../utils/fetcher'
import { ROOT_URL } from '../utils/config'

type LogoutOpts = {
  redirectUrl?: string
}

const logout = (opts: LogoutOpts = {}) => {
  const { redirectUrl } = opts

  if (typeof window !== 'undefined') {
    if ('localStorage' in window && redirectUrl) {
      try {
        window.localStorage.setItem('postLogoutRedirectUrl', redirectUrl)
      } catch {
        console.error('error setting "postLogoutRedirectUrl" on localStorage')
      }
    }

    window.open(`${ROOT_URL}/api/logout`, '_top')
  }
}

type UseAuthProps = {
  required?: boolean
}

const useAuth = (opts: UseAuthProps = {}) => {
  const { required = false } = opts
  const { data: swrRes, error: swrError } = useSWR('/api/me', fetcher, {
    refreshInterval: 0,
    shouldRetryOnError: false,
  })
  const router = useRouter()

  const error = swrError || swrRes?.data?.error
  const loading = !swrRes && !error
  const data = swrRes?.data
  const user = !error ? data : null

  if (typeof window !== 'undefined') {
    if (required) {
      if (!loading && !user) {
        router.push('/api/login')
      }
    }
  }

  const redirectReason = router?.query?.['redirect-reason']

  useEffect(() => {
    if (typeof window !== 'undefined' && 'localStorage' in window) {
      let postLogoutRedirectUrl
      try {
        postLogoutRedirectUrl = window.localStorage.getItem('postLogoutRedirectUrl')
      } catch {
        console.error('error getting "postLogoutRedirectUrl" from localStorage')
      }

      if (redirectReason === 'logout' && postLogoutRedirectUrl) {
        try {
          localStorage.removeItem('postLogoutRedirectUrl')
        } catch {
          console.error('error removing "postLogoutRedirectUrl" from localStorage')
        }

        window.open(postLogoutRedirectUrl, '_self')
      }
    }
  }, [redirectReason])

  return { user, loading, error, logout }
}

export default useAuth
