import 'isomorphic-unfetch'
import Router from 'next/router'

type Res = Response & {
  data?: any
  nextPage?: string
  count?: number
}

const fetcher = async (input: RequestInfo, init?: RequestInit): Promise<Res> => {
  return fetch(input, init).then((res) => {
    const contentType = res.headers.get('Content-Type')
    const nextPage = res.headers.get('X-Next-Page')
    const count = res.headers.get('X-Total-Count')

    if (!res.ok) {
      if (!['/api/me'].includes(input as string)) {
        switch (res.statusText) {
          case 'Unauthorized':
            if (typeof window !== 'undefined') {
              Router.push('/api/login')
            }

            break
        }
      }

      return res
        .json()
        .then((errorData) => {
          throw new Error(errorData)
        })
        .catch(() => {
          throw new Error(res.statusText)
        })
    }

    if (contentType?.includes('application/json')) {
      return res.json().then((data) => ({
        ...res,
        data,
        nextPage,
        count,
      }))
    }

    return res
  })
}

export const isoFetch = (url: string, init: RequestInit, accessToken?: string) => {
  const options = { ...init }

  if (accessToken) {
    options.headers = {
      Authorization: `Bearer ${accessToken}`,
    }
  }

  return fetcher(url, options)
}

export default fetcher
