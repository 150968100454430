import { theme } from '@traefiklabs/faency'

export default {
  ...theme,
  colors: {
    ...theme.colors,
    bg: '#F7F9FF',
    dark: '#110325',
    primary: '#1a2243',
  },
}
